import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { trace } from '@opentelemetry/api';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';

// Configure the Honeycomb exporter
const exporter = new OTLPTraceExporter({
    url: 'https://api.honeycomb.io:443/v1/traces',
    headers: {
        'x-honeycomb-team': process.env.HONEYCOMB_API_KEY,
        'x-honeycomb-dataset': 'pos-frontend-webapp',
    },
});

const exporterSelfHosted = new OTLPTraceExporter({
    url: process.env.OTEL_COLLECTOR_URL,
});

export const getTracking = (settings) => {
    const provider = new WebTracerProvider({
        resource: new Resource({
            [SemanticResourceAttributes.SERVICE_NAME]: 'pos-browser-' + process.env.VERCEL_ENV,
            [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: process.env.VERCEL_ENV,
            [SemanticResourceAttributes.SERVICE_VERSION]: process.env.VERCEL_GIT_COMMIT_SHA,
            'git.commit-message': process.env.VERCEL_GIT_COMMIT_MESSAGE,
            'git.branch': process.env.VERCEL_GIT_COMMIT_REF,
            'fiveten.tenant.id': settings.hash,
            'fiveten.saleunit.id': settings.saleUnit,
            'fiveten.saleunit.deviceid': settings.device,
        }),
    });

    provider.addSpanProcessor(new BatchSpanProcessor(exporter));
    provider.addSpanProcessor(new BatchSpanProcessor(exporterSelfHosted));
    provider.register();

    // Register any automatic instrumentation plugins you need
    registerInstrumentations({
        instrumentations: [
            getWebAutoInstrumentations(),
            new UserInteractionInstrumentation({
                eventNames: ['keypress', 'submit', 'click'],
            }),
        ],
    });

    return (eventName, eventProperties) => {
        const tracer = trace.getTracer();
        tracer.startActiveSpan('track-event', (span) => {
            span.setAttribute('event.name', eventName);

            // You can add any JSON object as attributes
            if (eventProperties) {
                Object.keys(eventProperties).forEach((key) => {
                    span.setAttribute(key, eventProperties[key]);
                });
            }
            // End the span
            span.end();
        });
    };
};
